@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

.font-share-tech-mono {
  font-family: 'Share Tech Mono', monospace;
}

.focus-pink-500 {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-pink-500;
}

.radio,
.checkbox {
  @apply flex items-center;
}

.radio.disabled,
.checkbox.disabled {
  @apply opacity-40;
}

.radio > input,
.checkbox > input {
  @apply cursor-pointer text-pink-600 disabled:cursor-not-allowed focus-pink-500;
}

.radio > label,
.checkbox > label {
  @apply cursor-pointer text-white pl-2;
}

.radio.disabled > label,
.checkbox.disabled > label {
  @apply cursor-not-allowed;
}

.checkbox > input {
  @apply rounded;
}

.icon-button {
  @apply rounded text-white hover:text-pink-600 focus-pink-500;
}

.button {
  @apply rounded px-3 py-1.5 text-white bg-pink-600 hover:bg-pink-700 active:bg-pink-800 focus-pink-500;
}

.input {
  @apply w-full px-3 py-1.5 rounded bg-neutral-700 text-white border border-white focus:border-pink-500 focus:ring-1 focus:ring-pink-500 focus:outline-none;
}

.top-shadow {
  box-shadow: inset 0 0.25rem 0.125rem 0 rgba(0, 0, 0, .5);
}

.fade {
  @apply transition-opacity ease-in-out duration-300;
}

.tippy-box[data-theme~='light'] {
  @apply bg-neutral-300 text-neutral-900 font-medium;
}

.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow::before {
  @apply border-l-neutral-300;
}

.tippy-box[data-theme~='success'] {
  @apply bg-green-700 text-white font-medium;
}

.tippy-box[data-theme~='success'][data-placement^='left'] > .tippy-arrow::before {
  @apply border-l-green-700;
}

.tippy-box[data-theme~='error'] {
  @apply bg-red-700 text-white font-medium;
}

.tippy-box[data-theme~='error'][data-placement^='left'] > .tippy-arrow::before {
  @apply border-l-red-700;
}

.autosize {
  @apply grid select-none;
}

.autosize::after {
  @apply invisible;
  content: attr(data-content) " ";
}

.autosize > textarea {
  @apply resize-none overflow-hidden;
}

.autosize > textarea,
.autosize::after {
  @apply input whitespace-pre-wrap break-words min-w-0;
  grid-area: 1 / 1 / 2 / 2;
}